<template>
  <section>
    <!--
    <BrunaForm
      ref="capacidadCosechaRef"
      v-bind:fields="this.capacidad_cosecha.fields"
      :card="this.capacidad_cosecha.card"
      @change="capacidadCosechaChange"
    ></BrunaForm>
-->
<!--
    <BrunaForm
      ref="capacidadAcopioRef"
      v-bind:fields="this.capacidad_acopio.fields"
      :card="this.capacidad_acopio.card"
      @change="capacidadAcopioChange"
    ></BrunaForm>
-->
    <tabla-degradacion-mensual
      ref="tablaDegradacionMensualRef"
      :dataUrl="porcentajeDegradacionMensual"
      :editable="!disabled"
      @change="tablaDegradacionMensualChange"
    ></tabla-degradacion-mensual>

    <b-card v-if="!disabled">
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        > 
      </b-card-body>
    </b-card>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";




import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import TablaDegradacionMensual from "./TablaDegradacionMensual.vue";

import axios from '@axios'

export default {

  created()
  {



  },


  data()
  {



    let capacidades_cosecha_mensuales = []
    let capacidades_acopio_mensuales = []


    for (let mes = 1; mes <= 12; mes++)
    {
      capacidades_cosecha_mensuales.push(

        {
          label: "Mes " + mes,
          type: "input",
          id: 'capacidad_cosecha_m' + mes,
          rules: 'required|numeric2',
          tooltip: null,
          format: {
            xs: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2
          },
          value: null,
          disabled: this.disabled
        },
      );

      capacidades_acopio_mensuales.push(

        {
          label: "Mes " + mes,
          type: "input",
          id: 'capacidad_acopio_m' + mes,
          rules: 'required|numeric2',
          tooltip: null,
          format: {
            xs: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2
          },
          value: null,
          disabled: this.disabled
        },
      );


    }


    return {

      unsavedChanges: false,


      capacidad_cosecha: {
        card: {
          title: "Capacidad Cosecha Mensual N Piezas",
          subtitle: "",
          tooltip: ""
        },
        fields: [
          ...capacidades_cosecha_mensuales
        ]
      },

      capacidad_acopio: {
        card: {
          title: "Capacidad Acopio Mensual Toneladas Peso Vivo",
          subtitle: "",
          tooltip: ""
        },
        fields: [
          ...capacidades_acopio_mensuales
        ]
      },


      porcentajeDegradacionMensual: useApiServices.ajustesCosechaProduccion.porcentajeDegradacionMensual,







    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    BrunaForm,
    AgGridTable,
    TablaDegradacionMensual,
    ToastificationContent
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

    props: {

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },


  },

  mounted()
  {
    this.getLastSetting()
      .then((response) =>
      {
        if (response.data)
        {
          let metadata = response.data.meta_data

          let capacidad_cosecha = metadata.capacidad_cosecha

          for (let property in capacidad_cosecha)
          {
            let field = this.findFieldByName(this.capacidad_cosecha.fields, property)

            field.value = capacidad_cosecha[property]
          }

          let capacidad_acopio = metadata.capacidad_acopio

          for (let property in capacidad_acopio)
          {
            let field = this.findFieldByName(this.capacidad_acopio.fields, property)

            field.value = capacidad_acopio[property]
          }

          this.$refs.tablaDegradacionMensualRef.rowData = metadata.porcentaje_degradacion_mensual


        }

        console.log(response)

      })

  },


  beforeRouteLeave(to, from, next)
  {




    if (this.unsavedChanges)
    {
      this.$bvModal
        .msgBoxConfirm('Tus cambios no se han guardado. ¿Descartar cambios?', {
          title: 'Confirmar descartar cambios',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Aceptar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value =>
        {
          if (value)
          {
            next()
          } 
          else
          {
            next(false)
          }

        })

    }
    else
    {
      next()
    }

  },


  methods: {


    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },


    getLastSetting()
    {
      return axios.request({
        method: "get",
        url: useApiServices.settingsLast,
        headers: {
          Accept: "application/json",
        },
      })
    },


    formatData()
    {

      return {
        //capacidad_cosecha: this.capacidad_cosecha.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        //capacidad_acopio: this.capacidad_acopio.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        porcentaje_degradacion_mensual: this.$refs.tablaDegradacionMensualRef.rowData
      }


    },

    postData()
    {



      useApiServices.saveAjustesProduccion(this.formatData(), this)
        .then((response) =>
        {
          this.unsavedChanges = false;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `Los ajustes se han guardado exitosamente.`,
            },
          });


        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar los ajustes</b>.`,
            },
          });

        });





    },

    save()
    {

      let self = this;

      this.postData()


    },
    save2()
    {

      let self = this;

      Promise.all([
        this.$refs.capacidadCosechaRef.validate(),
        this.$refs.capacidadAcopioRef.validate(),


      ]).then(([capacidadCosechaValid, capacidadAcopioValid]) =>
      {

        console.log(self.formatData())

        if (capacidadCosechaValid && capacidadAcopioValid)
        {
          this.postData()
        }




      })

    },


    capacidadCosechaChange()
    {
      console.log("capacidadCosechaChange");
      this.unsavedChanges = true;

    },

    capacidadAcopioChange()
    {
      console.log("capacidadAcopioChange");
      this.unsavedChanges = true;


    },

    tablaDegradacionMensualChange()
    {
      console.log("tablaDegradacionMensualChange");
      this.unsavedChanges = true;

    }





  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

